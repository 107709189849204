import React, { useState } from 'react';
import SectionItem from './SectionItem';

function SectionsList({ 
    isTrainer, 
    isTemplate,
    sections, 
    onEdit, 
    onDuplicate, 
    onDelete, 
    onDeleteActivity, 
    onActivityClick, 
    onNewActivityClick,
    onAddNewWorkoutButtonTap,
    onDeleteWorkoutButtonTapped,
    onCompleteWorkoutTapped,
    onAddNewWorkoutSet,
    onEditSetInWorkout,
    onDeleteSetInWorkout,
 }) {
    const [expandedSections, setExpandedSections] = useState({});

    const toggleSectionExpanded = (sectionId) => {
        setExpandedSections(prevState => ({
            ...prevState,
            [sectionId]: !prevState[sectionId]
        }));
    };

    return (
        <div>
            {sections.map(section => (
                <SectionItem
                    key={section._id}
                    section={section}
                    isExpanded={!!expandedSections[section._id]}
                    onExpandToggle={() => toggleSectionExpanded(section._id)}
                    isTrainer={isTrainer}
                    isTemplate={isTemplate}
                    onEdit={onEdit}
                    onDuplicate={onDuplicate}
                    onDelete={onDelete}
                    onDeleteActivity={onDeleteActivity}
                    onClickOnActivity={onActivityClick}
                    onClickOnNewActivity={onNewActivityClick}
                    onAddNewWorkoutButtonTapped={onAddNewWorkoutButtonTap}
                    onDeleteWorkoutButtonTap={onDeleteWorkoutButtonTapped}
                    onCompleteWorkoutTapped={onCompleteWorkoutTapped}
                    onAddNewWorkoutSet={onAddNewWorkoutSet}
                    onEditSetInWorkout={onEditSetInWorkout}
                    onDeleteSetInWorkout={onDeleteSetInWorkout}
                />
            ))}
        </div>
    );
}

export default SectionsList;