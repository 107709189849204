import React, { useCallback, useState, useEffect } from "react";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { getCheckoutSessionStatus } from '../../api/APIManager';

const CheckoutCompletionPage = () => {
  const mailToText = `mailto:${process.env.REACT_APP_CONTACT_EMAIL}`;
  const [status, setStatus] = useState('loading');
  const [customerEmail, setCustomerEmail] = useState('');
  const [itemsPurchased, setItemsPurchased] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');


    getCheckoutSessionStatus(sessionId)
      .then((response) => {
        console.log(response.data)
        setStatus(response.data.status);
        setCustomerEmail(response.data.customer_email);
        setItemsPurchased(response.data.itemsPurchased || []);
      })
      .catch((error) => {
        console.error('An error occurred while fetching the checkout session data: ', error);
      });
  }, []);

  const handleTakeMeToMyProgram = () => {
    navigate('/program')
  }

  if (status === 'open') {
    navigate('/checkout')
  }

  const purchasedItem = () => {
    return (
      <div>
        Purchased <strong>{itemsPurchased[0].description}</strong> for <strong>${itemsPurchased[0].amount_total / 100}</strong>
      </div>
    )
  };


  if (status === 'complete') {
    return (
      <div>
        <div className="header">
          <div className="header-title">Purchase Complete</div>
        </div>
        <section id="success">
          <p>
            We appreciate your business! A confirmation email will be sent to {customerEmail}.
          </p>
          <p>
            {purchasedItem()}
          </p>
        </section>
        <div>
          If you have any questions, please email <a href={mailToText}>{process.env.REACT_APP_CONTACT_EMAIL}</a>.
        </div>
        <div style={{ marginTop: "40px" }}>
          <Button primary onClick={() => handleTakeMeToMyProgram()}>Take me to my program</Button>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div className="header">
          <div className="header-title">Purchase Loading</div>
        </div>
        <p>
          Loading...
        </p>
      </div>
    )
  }
}

export default CheckoutCompletionPage;