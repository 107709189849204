import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import { getUserFromToken, changePassword } from '../api/authService';
import Button from "../components/Button";
import Modal from "../components/Modal";
import "./AccountPage.css";


function AccountPage() {
  const { token, logout } = useAuth();
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      // Redirect to login page if the user is not authenticated
      window.location.replace('/login');
      return;
    }

    // Fetch user details from your API
    const fetchUserDetails = async () => {
      try {
        const response = await getUserFromToken(token);
        // console.log(response);
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [token]);

  const handleChangePassword = async (password) => {

    try {
      const response = await changePassword(password);
      setUser(response.data);
      setShowModal(false);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const changePasswordView = (
    <div className="modal">
      <div className="modal-content">
        <h4>Change Password</h4>
        <input
          type="password"
          placeholder="Current Password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <div className="button-container">
          <button onClick={handleChangePassword}>Submit</button>
          <button onClick={() => setShowModal(false)}>Cancel</button>
        </div>
      </div>
    </div>
  )

  if (!user) {
    return <div>Loading...</div>;
  }

  const handleShowPayment = () => {
    navigate(`/payment/${user.stripeId}`);
  }

  const paymentStatusView = () => {
    let paymentStatus = 'Registered'
    let showPaymentOptions = false

    switch (user.status) {
      case 'registered':
        paymentStatus = 'Registered';
        showPaymentOptions = true;
        break;
      case 'suspended':
        paymentStatus = 'Suspended';
        showPaymentOptions = true;
        break;
      case 'active_subscription':
        paymentStatus = 'Active Subscription';
        showPaymentOptions = false;
        break;
      case 'active_program':
        paymentStatus = 'Active Program';
        showPaymentOptions = true;
        break;
      case 'active_custom':
        paymentStatus = 'Active Custom';
        showPaymentOptions = false;
        break;
      case 'trial':
        paymentStatus = 'Trial';
        showPaymentOptions = true;
        break;
      case 'inactive':
        paymentStatus = 'Inactive';
        showPaymentOptions = true;
        break;
      case 'canceled':
        paymentStatus = 'Canceled';
        showPaymentOptions = true;
        break;

      default:
        paymentStatus = 'Registered';
        showPaymentOptions = true;
    }

    return (
      <div className="payment-container">
        <strong>Account Status: </strong> {paymentStatus}
        {showPaymentOptions && (
          <div className="button-container">
            <Button warning onClick={() => handleShowPayment()}>Payment Options</Button>
          </div>
        )}
      </div>
    )
  };

  return (
    <div>
      <div className="header">
        <div className="header-title">Account</div>
      </div>
      <div className="subtitle">Welcome, {user.firstName}!</div>
      <p>Email: {user.email}</p>
      {/* <img src={user.profilePicture} alt="Profile" /> */}
      {/* Add more user details as needed */}
      {showModal && (
        changePasswordView
      )}
      {paymentStatusView()}
      <div className="button-container">
        <Button primary onClick={() => setShowModal(true)}>Change Password</Button>
        <Button danger onClick={logout}>Logout</Button>
      </div>
    </div>
  );
}

export default AccountPage;

function AppleLogin() {
  // const MyComponent = props => {
  //   useScript('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js');

  //   // rest of your component
  // }

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <>
      <meta name="appleid-signin-client-id" content="[CLIENT_ID]" />
      <meta name="appleid-signin-scope" content="[SCOPES]" />
      <meta name="appleid-signin-redirect-uri" content="[REDIRECT_URI]" />
      <meta name="appleid-signin-state" content="[STATE]" />
      <meta name="appleid-signin-nonce" content="[NONCE]" />
      <meta name="appleid-signin-use-popup" content="true" />
      <div className="signin-button"
        id="appleid-signin"
        data-color="black"
        data-border="true"
        data-type="sign in"
      />
    </>
  )
}

function SignUpPage({ handleOnSubmit }) {
  return (
    <div>
      <h1>Sign Up</h1>
      <form onSubmit={handleOnSubmit}>
        <input type="email" placeholder="Email" name="email" />
        <input type="password" placeholder="Password" name="password" />
        <input type="submit" value="Sign Up" />
      </form>
    </div>
  );
}