import axios from 'axios';
import { logout } from './authService';

const api = axios.create({
  baseURL:  process.env.REACT_APP_API_Base_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Set the AUTH token for any request
api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (window.location.pathname === '/login') {
    // Do not attach token for login route
    return config;
  }

  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});

// Handle token expiration globally
api.interceptors.response.use(
  (response) => response, // Pass successful responses
  async (error) => {
    const { response } = error;

    if (response && response.status === 401) {
      console.error('401 Unauthorized: Token might be expired.');

      // TODO: Refresh token logic (if server supports it)
      // if (localStorage.getItem('refreshToken')) {
      //   try {
      //     const newToken = await refreshToken();
      //     localStorage.setItem('token', newToken); // Update token in localStorage
      //     error.config.headers.Authorization = `Bearer ${newToken}`; // Retry original request
      //     return api.request(error.config);
      //   } catch (refreshError) {
      //     console.error('Token refresh failed. Logging out user.');
      //     logout();
      //     window.location.href = '/login'; // Redirect to login page
      //   }
      // } else {
        // No refresh token available, log out
        logout();
        if (window.location.pathname !== '/login') {
          window.location.href = '/login'; // Redirect to login page
        }
      // }
    }

    if (response && response.status === 403) {
      console.error('403 Forbidden: User does not have access.');
      // TODO: Add Forbidden page
    }

    return Promise.reject(error); // Pass error to the calling function
  }
);

export default api;