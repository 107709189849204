import React from "react";
import './SectionItem.css';
import Button from "../../components/Button";
import ProgramItem from './ProgramItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSave, faCopy, faChevronDown, faChevronUp, faEdit } from '@fortawesome/free-solid-svg-icons';
import DeleteButton from "../../components/DeleteButton";


function SectionItem({ 
    section, 
    onExpandToggle, 
    isExpanded, 
    isTrainer, 
    isTemplate,
    onClickOnActivity, 
    onClickOnNewActivity, 
    onAddNewWorkoutButtonTapped, 
    onDeleteWorkoutButtonTap,
    onDeleteActivity,
    onCompleteWorkoutTapped,
    onAddNewWorkoutSet,
    onEditSetInWorkout,
    onDeleteSetInWorkout, 
    handleEdit, 
    handleDuplicate,
    handleDelete, 
    onEdit, 
    onDuplicate, 
    onDelete }) {
    const [isEditing, setIsEditing] = React.useState(false);
    const [updatedSectionTitle, setUpdatedSectionTitle] = React.useState(section.title);
    const handleClick = (e) => {
        // navigate(`/dayDetail/1/workout/${workout.id}`)
        // navigate(`/client/program/1/activities/${currentActivity.id}/workouts/${workout.id}`);
        // setSelectedWorkout(index);

        if (!e.target.closest('.section-content') && !e.target.closest('.box')) {
            // Only collapse or expand when tapping on the section item (week)
            onExpandToggle()
        }
    }

    const handleOnEdit = (e) => {
        e.stopPropagation();
        onEdit(updatedSectionTitle, section._id);
        setIsEditing(!isEditing);
    }

    const handleOnDuplicate = (e) => {
        e.stopPropagation();
        onDuplicate(section);
    }

    const handleOnDelete = () => {
        onDelete(section);
    }

    const handleAddNewActivity = (e) => {
        e.stopPropagation();
        onClickOnNewActivity(section._id);
    }

    const titleSection = () => {
        if (isEditing) {
            return (
                <div className="section-header-title">
                    <input type="text"  autoFocus value={updatedSectionTitle || ''} onChange={(e) => setUpdatedSectionTitle(e.target.value)} />
                </div>
            )
        } else {
            return (
                <div className="section-header-title">{section.title}</div>
            )
        }
    }

    const trainerSectionButtons = () => {
        if (isEditing) {
            return (
                <div className="section-header-content">
                    <Button warning onClick={handleOnEdit}>Save <><FontAwesomeIcon icon={faSave} className="section-button-icon"/></></Button>
                </div>
            )
        } else {
            return (
                <div className="section-header-content">
                    <Button warning onClick={handleOnEdit}>Edit <><FontAwesomeIcon icon={faEdit} className="section-button-icon"/></></Button>
                    <Button primary onClick={(e) => handleOnDuplicate(e)}>Duplicate <><FontAwesomeIcon icon={faCopy} className="section-button-icon"/></></Button>
                    <DeleteButton onClick={() => handleOnDelete()}>Delete <><FontAwesomeIcon icon={faTrash} className="section-button-icon"/></></DeleteButton>
                </div>
            )
        }
    }

    const showAddActivityButton = () => {
        return (
            <div>
                <Button primary onClick={(e) => handleAddNewActivity(e)}>Add New Activity</Button>
            </div>
        )
    }

    // const handleAddNewWorkoutButton = (activity, sectionID) => {
    //     onAddNewWorkoutButtonTap(activity, sectionID)
    // }

    const renderedActivities = section.activities.map((activity, index) => {
        return (
            <ProgramItem 
                isClient={!isTrainer} 
                isTemplate={isTemplate}
                handleOnClick={onClickOnActivity} 
                activity={activity} 
                sectionID={section._id} 
                key={activity._id} 
                handleDelete={() => onDeleteActivity(activity, section._id)} 
                onAddNewWorkoutButtonTap={onAddNewWorkoutButtonTapped}
                onDeleteWorkoutButtonTapped={onDeleteWorkoutButtonTap}
                onCompleteWorkoutTapped={onCompleteWorkoutTapped}
                onAddNewWorkoutSet={onAddNewWorkoutSet}
                onEditSetInWorkout={onEditSetInWorkout}
                onDeleteSetInWorkout={onDeleteSetInWorkout}
            />
        )
    });

    const expandedSectionView = () => {
        return (
            <div>
                {renderedActivities.length > 0 ? renderedActivities : "No Activities for Section"}
                {isTrainer && showAddActivityButton()}
            </div>
        )
    }

    const collapsedSectionView = () => {
        return (
            <div className="box-content">
                <div className="box-description">Activities: {section.activities.length}</div>
            </div>
        )
    }

    return (
        <div className="section-box" onClick={handleClick}>
            <div className="section-header">
                {isExpanded && (
                    <><FontAwesomeIcon icon={faChevronDown} /></>
                )}
                {!isExpanded && (
                    <><FontAwesomeIcon icon={faChevronUp} /></>
                )}
                {titleSection()}
                {isTrainer && trainerSectionButtons()}
            </div>
            {isExpanded ? expandedSectionView() : collapsedSectionView()}
        </div>
    )
}

export default SectionItem;