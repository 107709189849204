import React from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import "./RegistrationPage.css";
import { useAuth } from "../context/AuthProvider";

function RegistrationPage() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();
    const { registerNewClient } = useAuth();

    const onSubmit = async (data) => {
        console.log(data);
       
        const response = await registerNewClient(data)

        console.log(response);
        if (!response.success) {
            alert("Registration Failed! " + response.error);
        } else {
            alert("Registration Successful!");
            // Go to Payment flow
            navigate(`/payment/${response.registeredUser.stripeId}`);
        }
    };

    return (
        <div className="registration-container">
            <form className="registration-form" onSubmit={handleSubmit(onSubmit)}>
                <h1>Register</h1>

                <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                        type="text"
                        id="firstName"
                        {...register("firstName", { required: "First name is required" })}
                        placeholder="Enter your first name"
                    />
                    {errors.firstName && <p className="error">{errors.firstName.message}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                        type="text"
                        id="lastName"
                        {...register("lastName", { required: "Last name is required" })}
                        placeholder="Enter your last name"
                    />
                    {errors.lastName && <p className="error">{errors.lastName.message}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: "Enter a valid email",
                            },
                        })}
                        placeholder="Enter your email"
                    />
                    {errors.email && <p className="error">{errors.email.message}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        {...register("password", {
                            required: "Password is required",
                            minLength: {
                                value: 6,
                                message: "Password must be at least 6 characters long",
                            },
                        })}
                        placeholder="Enter your password"
                    />
                    {errors.password && <p className="error">{errors.password.message}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                        type="tel"
                        id="phoneNumber"
                        {...register("phoneNumber", {
                            pattern: {
                                value: /^\+?[1-9]\d{1,14}$/,
                                message: "Enter a valid phone number",
                            },
                        })}
                        placeholder="Enter your phone number"
                    />
                    {errors.phoneNumber && <p className="error">{errors.phoneNumber.message}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="height">Height (inches)</label>
                    <input
                        type="number"
                        id="height"
                        {...register("height", {
                            required: "Height is required",
                            min: { value: 36, message: "Height must be at least 36 inches" }, // 3 feet minimum
                            max: { value: 96, message: "Height must be less than 96 inches" }, // 8 feet maximum
                        })}
                        placeholder="Enter your height in inches"
                    />
                    {errors.height && <p className="error">{errors.height.message}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="weight">Weight (kg)</label>
                    <input
                        type="number"
                        id="weight"
                        {...register("weight", {
                            valueAsNumber: true,
                            min: { value: 0, message: "Weight must be a positive number" },
                        })}
                        placeholder="Enter your weight"
                    />
                    {errors.weight && <p className="error">{errors.weight.message}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="sex">Sex</label>
                    <select id="sex" {...register("sex", { required: "Sex is required" })}>
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                    {errors.sex && <p className="error">{errors.sex.message}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="goalDescription">Your Goal</label>
                    <textarea
                        id="goalDescription"
                        rows="4"
                        {...register("goalDescription", {
                            required: "Please describe your goal",
                            maxLength: {
                                value: 500,
                                message: "Goal description cannot exceed 500 characters",
                            },
                        })}
                        placeholder="Describe your goal (e.g., lose weight, gain muscle)"
                    ></textarea>
                    {errors.goalDescription && (
                        <p className="error">{errors.goalDescription.message}</p>
                    )}
                </div>

                <button type="submit" className="submit-btn">Register</button>
            </form>
        </div>
    );
};

export default RegistrationPage;