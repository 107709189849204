import React, { useState, useEffect } from 'react';
import './Program.css';
import { useNavigate } from 'react-router-dom';
import { getClientProgram, updateUserWorkout } from '../shared/api/APIManager';
import { useAuth } from '../shared/context/AuthProvider';
import jwtDecode from 'jwt-decode';
import SectionsList from '../shared/pages/New/SectionList';

function Program() {
    const { token } = useAuth();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [program, setProgram] = useState({
        title: '',
        description: '',
        sections: [],
    });

    useEffect(() => {
        getClientProgram(jwtDecode(token).id)
            .then((response) => {
                setProgram(response.data);
                console.log('Program data: ', response.data);
            })
            .catch((error) => {
                console.error('An error occurred while fetching the data: ', error);
            });
    }, [token]);

    const handleClickActivity = (selectedActivity, sectionID) => {
        if (selectedActivity && selectedActivity._id) {
            navigate(`/programs/${program._id}/section/${sectionID}/activities/${selectedActivity._id}`, {
                state: { selectedActivity, isTrainer: false },
            });
        } else {
            console.log('Error: selectedActivity is undefined');
        }
    }

    const handleAddWorkoutButtonTap = (selectedActivity, sectionID) => {
        setIsModalOpen(!isModalOpen);
        console.log("Program.js recieved add workout button tap")
    }

    // ===============================
    //  #region - Workout Handelrs
    // ===============================

    // Workouts - Completed Check Box
    const handleCheckBoxTapped = async (workout, isCompleted) => {
        const updatedWorkoutData = { isCompleted };

        const updatedProgram = { ...program };

        // Find the section containing the activity
        updatedProgram.sections = updatedProgram.sections.map((section) => ({
            ...section,
            activities: section.activities.map((activity) => {
                if (activity._id === workout.activityId) {
                    return {
                        ...activity,
                        workouts: activity.workouts.map((workoutItem) =>
                            workoutItem._id === workout._id
                                ? { ...workoutItem, isCompleted }
                                : workoutItem
                        ),
                    };
                }
                return activity;
            }),
        }));

        setProgram(updatedProgram);

        try {
            await updateUserWorkout(workout._id, updatedWorkoutData);
        } catch (error) {
            console.error("Failed to update workout on the server:", error);
        }
    }

    // Workout Set - Edit
    const handleUpdateSetInWorkout = (updatedSet, workout) => {
        // Note: API call done in the WorkoutSet. Just updating UI
        setProgram((prevProgram) => {
            // Create a new program object
            const updatedProgram = {
                ...prevProgram,
                sections: prevProgram.sections.map((section) => ({
                    ...section,
                    activities: section.activities.map((activity) => ({
                        ...activity,
                        workouts: activity.workouts.map((workoutItem) => {
                            if (workoutItem._id === workout._id) {
                                return {
                                    ...workoutItem,
                                    sets: workoutItem.sets.map((set) =>
                                        set._id === updatedSet._id ? { ...set, ...updatedSet } : set
                                    ),
                                };
                            }
                            return workoutItem;
                        }),
                    })),
                })),
            };

            return updatedProgram;
        });
    }
    // #endregion

    return (
        <div>
            <div className="header">
                <div className="header-title">{program.title}</div>
            </div>
            <div className="section-boxes-wrapper">
                {program.sections.length > 0 ? (
                    <SectionsList
                        sections={program.sections}
                        isTrainer={false}
                        isTemplate={false}
                        onActivityClick={handleClickActivity}
                        onAddWorkoutButtonTap={handleAddWorkoutButtonTap}
                        onEditSetInWorkout={handleUpdateSetInWorkout}
                        onCompleteWorkoutTapped={handleCheckBoxTapped}
                    />
                ) : "No Sections for Program"}
            </div>
        </div>
    );
}

export default Program;