import React, { useCallback, useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate, useLocation } from "react-router-dom";
import { createCheckoutSession } from '../../api/APIManager';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test secret API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CheckoutForm = ({ customerId}) => {
  const location = useLocation();
  const { customerStripeId, productPriceId, payMode, templateProgramId } = location.state || {};
  const fetchClientSecret = useCallback(() => {
    return createCheckoutSession({customerStripeId, productPriceId, payMode, templateProgramId })
      .then((response) => {
        console.log(response.data)
        return response.data.clientSecret
      })
      .catch((error) => {
        console.error('An error occurred while fetching the data: ', error);
      });
  }, []);

  const options = { fetchClientSecret };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}

export default CheckoutForm;