import React, { useState } from 'react';
import Button from './Button'; // Assuming your Button component is in this file path

const DeleteButton = ({ itemId, onClick }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleDeleteClick = () => setShowConfirmation(true);
  const handleConfirmDelete = () => {
    setShowConfirmation(false);
    onClick(itemId);  // This should now call your API to delete the item
  };

  return (
    <>
      <Button danger onClick={handleDeleteClick}>Delete</Button>
      {showConfirmation && (
        <div className="confirmation-dialog">
          <p>Are you sure you want to delete this item?</p>
          <Button danger onClick={handleConfirmDelete}>Yes, Delete</Button>
          <Button onClick={() => setShowConfirmation(false)}>Cancel</Button>
        </div>
      )}
    </>
  );
};

export default DeleteButton;