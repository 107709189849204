import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getProgramTemplate,
  createActivityAndAddToProgramTemplate,
  updateProgramTemplate,
  addNewSectionInProgramTemplate,
  duplicateSectionInProgramTemplate,
  updateSectionInProgramTemplate,
  createTemplateUserWorkout,
  addNewUserWorkoutToActivityForProgramTemplate,
  deleteSectionInProgramTemplate,
  deleteProgramTemplate,
  updateActivityForProgramTemplateSection,
  deleteActivityInProgramTemplateSection,
  deleteTemplateUserWorkout
} from '../shared/api/APIManager';
import Button from '../shared/components/Button';
import Modal from 'react-modal'; // Or your custom modal component
import "./TrainerProgramDetailPage.css";
import SectionsList from '../shared/pages/New/SectionList';
import WorkoutsList from '../shared/pages/New/WorkoutsList';

function TrainerProgramDetailPage() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewActivityModalOpen, setIsNewActivityModalOpen] = useState(false);
  const [isWorkoutListModalOpen, setIsWorkoutListModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [program, setProgram] = useState({
    title: '',
    description: '',
    sections: [],
  });
  const [sectionTitle, setSectionTitle] = useState('');
  const [newActivity, setNewActivity] = useState({
    title: '',
    workouts: [],
  });

  const [selectedSectionInfo, setSelectedSectionInfo] = useState({
    activityID: null,
    sectionID: null
  })

  const { id } = useParams();

  useEffect(() => {
    getProgramTemplate(id)
      .then((response) => {
        setProgram(response.data);
        console.log('Program data: ', response.data);
      })
      .catch((error) => {
        console.error('An error occurred while fetching the data: ', error);
      });
  }, []);

  // TODO: Handle slow internet loading for api calls

  // ===============================
  // #region - Section Handlers
  // ===============================

  // Section - Add: Show modal, listen for text change, send new section to api
  const handleAddSectionTapped = () => {
    setIsModalOpen(true);
  }

  const handleChange = (e) => {
    setSectionTitle(e.target.value);
  };

  const handleSectionEdit = (sectionTitleUpdate, sectionID) => {
    const sectionTitleUpdateData = {
      sectionId: sectionID,
      title: sectionTitleUpdate
    };

    updateSectionInProgramTemplate(program._id, sectionID, sectionTitleUpdateData)
      .then((response) => {
        console.log('Program section updated successfully');
        setProgram(response.data);
      })
      .catch((error) => {
        console.error('Error updating new section to program: ', error);
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newSection = { title: sectionTitle, activities: [] };
    program.sections.push(newSection);

    addNewSectionInProgramTemplate(program._id, newSection)
      .then((response) => {
        console.log('Program updated successfully');
        setProgram(response.data);
      })
      .catch((error) => {
        console.error('Error adding new section to program: ', error);
      });

    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setSectionTitle('');
    setIsModalOpen(false);
  }

  // Section - Duplicate
  const handleSectionDuplicate = (section) => {
    program.sections.push(section);

    duplicateSectionInProgramTemplate(program._id, section._id)
      .then((response) => {
        console.log('Program updated successfully');
        setProgram(response.data);
      })
      .catch((error) => {
        console.error('Error updating program: ', error);
      });
  }

  // Section - Delete
  const handleSectionDelete = (sectionToRemove) => {
    const newSections = program.sections.filter(section => section._id !== sectionToRemove._id);
    setProgram({ ...program, sections: newSections });
    let updatedProgram = { ...program, sections: newSections }

    updateProgramTemplate(updatedProgram._id, updatedProgram)
      .then((response) => {
        console.log('Program updated successfully');
        setProgram(response.data);
      })
      .catch((error) => {
        console.error('Error updating program: ', error);
      });
  }
  // #endregion

  // ===============================
  //  #region - Activity Handlers
  // ===============================

  // Activity - Add: Show modal, listen for text change, send new activity to api
  const handleNewActivityButtonTapped = (sectionID) => {
    console.log('handelNewActivityButtonClick on TrainerProgramDetailPage');
    setNewActivity({
      title: '',
      workouts: [],
      sectionID: sectionID
    })
    setIsNewActivityModalOpen(true);
  }

  const handleNewActivityChange = (e) => {
    setNewActivity({ ...newActivity, [e.target.name]: e.target.value });
  }

  const handleNewActivitySubmit = async (e) => {
    e.preventDefault();
    const updatedSection = program.sections.find(section => section._id === newActivity.sectionID);
    updatedSection.activities.push(newActivity);

    const newActivityToSendToServer = {
      activities: updatedSection.activities
    }

    updateSectionInProgramTemplate(program._id, newActivity.sectionID, newActivityToSendToServer)
      .then((response) => {
        console.log('Program section updated successfully');
        setProgram(response.data);
      })
      .catch((error) => {
        console.error('Error updating new section to program: ', error);
      });

    setNewActivity('');
    setIsNewActivityModalOpen(false);
  }

  const handleCloseNewActivityModal = () => {
    setNewActivity('');
    setIsNewActivityModalOpen(false);
  }

  // const handleClickActivity = (selectedActivity) => {
  //   if (selectedActivity && selectedActivity._id) {
  //     const section = program.sections.find(section => section.activities.some(activity => activity._id === selectedActivity._id));
  //     if (section) {
  //       navigate(`/program-templates/${program._id}/section/${section._id}/activities/${selectedActivity._id}`, {
  //         state: { selectedActivity },
  //       });
  //     } else {
  //       console.log('Error: section for activity is not found');
  //     }
  //   } else {
  //     console.log('Error: selectedActivity is undefined');
  //   }
  // }

  // TODO: Activity add duplicate and edit buttons

  // Activity - Delete:
  const handleDeleteActivity = async (activity, sectionID) => {
    const section = program.sections.find((s) => s._id === sectionID);
    if (!section) {
      console.error('Section not found');
      return;
    }

    const activityToDelete = section.activities.find((a) => a._id === activity._id);
    if (!activityToDelete) {
      console.error('Activity not found');
      return;
    }

    // Delete all userworkouts for saved program template
    activityToDelete.workouts.forEach(async (workout) => {
      try {
        const response = await deleteTemplateUserWorkout(workout._id);
        if (response.status === 200) {
          console.log('UserWorkouts for activity deleted successfully');
        } else {
          console.error('Error deleting user workouts for activity');
        }
      } catch (error) {
        console.error('Error deleting user workouts for activity:', error);
      }
    });

    deleteActivityInProgramTemplateSection(program._id, section._id, activityToDelete._id)
      .then((response) => {
        setProgram(response.data);
      })
      .catch((error) => {
        console.error('Error deleting activity from section to program: ', error);
      });
  }
  // #endregion

  // ===============================
  //  #region - Workout Handelrs
  // ===============================

  // Workouts - Add
  const handleAddNewWorkoutButtonTap = (activityID, sectionID) => {
    console.log("handleAddNewWorkoutButtonTap on TrainerProgramDetailPage")
    setSelectedSectionInfo({ activityID, sectionID })
    setIsWorkoutListModalOpen(true)
  }

  const handleSelectedWorkout = async (selectedWorkout) => {
    try {
      const newUserWorkout = {
        activityId: selectedSectionInfo.activityID,
        workoutId: selectedWorkout._id,
        isCompleted: false,
      }

      const userWorkoutResponse = await createTemplateUserWorkout(newUserWorkout);
      const newUserWorkoutData = userWorkoutResponse.data;

      // Create a deep copy of the program state to avoid direct mutation
      const updatedProgram = { ...program };
      const updatedSection = updatedProgram.sections.find(
        (section) => section._id === selectedSectionInfo.sectionID
      );

      if (!updatedSection) {
        throw new Error(`Section with ID ${selectedSectionInfo.sectionID} not found.`);
      }

      const updatedActivity = updatedSection.activities.find(
        (activity) => activity._id === selectedSectionInfo.activityID
      );

      if (!updatedActivity) {
        throw new Error(`Activity with ID ${selectedSectionInfo.activityID} not found.`);
      }

      updatedActivity.workouts.push(newUserWorkoutData);

      setProgram(updatedProgram);

      // Sync the updated activity with the backend
      const updatedWorkoutsIds = updatedActivity.workouts.map((workout) => workout._id);
      await updateActivityForProgramTemplateSection(program._id, selectedSectionInfo.sectionID, updatedActivity._id, {
        workouts: updatedWorkoutsIds,
      });

      // Close the workout modal
      setIsWorkoutListModalOpen(false);
      setSelectedSectionInfo({
        activityID: null,
        sectionID: null
      })
    } catch (error) {
      console.error('Error adding new workout:', error);
    }
  }

  // Workouts - Delete
  const handleOnDeleteWorkoutButtonTapped = async (sectionID, activityID, workoutID) => {
    try {
      // Create a deep copy of the program state to avoid direct mutation
      const updatedProgram = { ...program };

      // Find the correct section
      const updatedSection = updatedProgram.sections.find(
        (section) => section._id === sectionID
      );

      if (!updatedSection) {
        throw new Error(`Section with ID ${sectionID} not found.`);
      }

      // Find the correct activity
      const updatedActivity = updatedSection.activities.find(
        (activity) => activity._id === activityID
      );

      if (!updatedActivity) {
        throw new Error(`Activity with ID ${activityID} not found.`);
      }

      // Remove the workout from the activity's workouts array
      updatedActivity.workouts = updatedActivity.workouts.filter(
        (workout) => workout._id !== workoutID
      );

      // Update the state immediately for a responsive UI
      setProgram(updatedProgram);

      await deleteTemplateUserWorkout(workoutID);

      // Sync the updated activity with the backend
      const updatedWorkoutsIds = updatedActivity.workouts.map((workout) => workout._id);
      await updateActivityForProgramTemplateSection(program._id, sectionID, updatedActivity._id, {
        workouts: updatedWorkoutsIds,
      });

      console.log('Workout successfully deleted and program template updated.');
    } catch (error) {
      console.error('Error deleting workout:', error);
    }
  }
  // #endregion

  // ===============================
  // #region - Workout Set Handlers
  // ===============================

  // Workout Set - Add
  const handleAddSetToWorkout = (newSet, workout) => {
    // Note: API call done in the WorkoutSet. Just updating UI
    const updatedProgram = { ...program };

    // Find the section containing the activity
    updatedProgram.sections.forEach((section) => {
      section.activities.forEach((activity) => {
        if (activity._id === workout.activityId) {
          // Find the workout
          activity.workouts.forEach((workoutItem) => {
            if (workoutItem._id === workout._id) {
              // Add the new set
              workoutItem.sets.push(newSet);
            }
          });
        }
      });
    });

    setProgram(updatedProgram);
  }

  // Workout Set - Edit
  const handleUpdateSetInWorkout = (updatedSet, workout) => {
    // Note: API call done in the WorkoutSet. Just updating UI
    const updatedProgram = { ...program };

    // Find the section containing the activity
    updatedProgram.sections.forEach((section) => {
      section.activities.forEach((activity) => {
        if (activity._id === workout.activityId) {
          // Find the workout
          activity.workouts.forEach((workoutItem) => {
            if (workoutItem._id === workout._id) {
              // Find and update the set
              workoutItem.sets = workoutItem.sets.map((set) =>
                set._id === updatedSet._id ? { ...set, ...updatedSet } : set
              );
            }
          });
        }
      });
    });

    setProgram(updatedProgram);
  }

  // Workout Set - Delete
  // Workout Set - Delete
  const handleDeleteSetInWorkout = (deletedSet, workout) => {
    // Note: API call done in the WorkoutSet. Just updating UI
    const updatedProgram = { ...program };

    // Find the section containing the activity
    updatedProgram.sections.forEach((section) => {
      section.activities.forEach((activity) => {
        if (activity._id === workout.activityId) {
          // Find the workout
          activity.workouts.forEach((workoutItem) => {
            if (workoutItem._id === workout._id) {
              // Filter out the deleted set
              workoutItem.sets = workoutItem.sets.filter((set) => set._id !== deletedSet._id);
            }
          });
        }
      });
    });

    setProgram(updatedProgram);
  }
  // #endregion

  // ===============================
  //  #region - Modals
  // ===============================

  const newSectionModal = () => {
    return (
      <Modal className="ReactModal__Overlay" isOpen={isModalOpen} onRequestClose={() => handleCloseModal()}>
        <div className="form-box">
          <h1>Create an Section</h1>
          <form onSubmit={handleSubmit}>
            <label>
              Title:
              <input
                type="text"
                name="title"
                placeholder="Week 1"
                value={sectionTitle}
                onChange={handleChange}
              />
            </label>
            {message && <p>{message}</p>}
            <Button primary type="submit">Create Section</Button>
          </form>
          <div className="grid-container">
            <Button secondary onClick={() => handleCloseModal()}>Cancel</Button>
          </div>
        </div>
      </Modal>
    );
  }

  const newActivityModal = () => {
    return (
      <Modal className="ReactModal__Overlay" isOpen={isNewActivityModalOpen} onRequestClose={() => handleCloseModal()}>
        <div className="form-box">
          <h1>Create an Activity</h1>
          <form onSubmit={handleNewActivitySubmit}>
            <label>
              Title:
              <input
                type="text"
                name="title"
                placeholder="Day 1 or Monday Workouts"
                value={newActivity.title}
                onChange={handleNewActivityChange}
              />
            </label>
            {message && <p>{message}</p>}
            <Button primary type="submit">Create Activity</Button>
          </form>
          <div className="grid-container">
            <Button secondary onClick={() => handleCloseNewActivityModal()}>Cancel</Button>
          </div>
        </div>
      </Modal>
    );
  }

  const workoutListModal = () => {
    return (
      <Modal
        className="workoutlist-modal"
        isOpen={isWorkoutListModalOpen} onRequestClose={() => handleCloseModal()}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.85)'
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '80%',
            backgroundColor: 'white'
          }
        }}>
        <WorkoutsList onSelectedWorkout={handleSelectedWorkout} />
      </Modal>
    );
  }
  // #endregion

  return (
    <div>
      <div className="header">
        <div className="header-title">{program.title}</div>
        <div><Button primary onClick={() => handleAddSectionTapped()}>Add Section</Button></div>
      </div>
      <p className="program-description">{program.description}</p>
      <div className="section-boxes-wrapper">
        {program.sections.length > 0 ? (
          <SectionsList
            sections={program.sections}
            isTrainer={true}
            isTemplate={true}
            onEdit={handleSectionEdit}
            onDuplicate={handleSectionDuplicate}
            onDeleteActivity={handleDeleteActivity}
            onDelete={handleSectionDelete}
            onNewActivityClick={handleNewActivityButtonTapped}
            onAddNewWorkoutButtonTap={handleAddNewWorkoutButtonTap}
            onDeleteWorkoutButtonTapped={handleOnDeleteWorkoutButtonTapped}
            onAddNewWorkoutSet={handleAddSetToWorkout}
            onEditSetInWorkout={handleUpdateSetInWorkout}
            onDeleteSetInWorkout={handleDeleteSetInWorkout}
          />
        ) : "No Sections for Program"}
      </div>
      {newSectionModal()}
      {newActivityModal()}
      {workoutListModal()}
    </div>
  )
};

export default TrainerProgramDetailPage;