import React, { useState } from 'react';
import Button from './Button'; // Assuming your Button component is in this file path

const ConfirmationButton = ({ confirmationText, itemId, onClick, ...rest }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleConfirmClick = (e) => {
    e.stopPropagation();
    setShowConfirmation(true);
  };

  const handleConfirm = (e) => {
    e.stopPropagation();
    setShowConfirmation(false);
    onClick(itemId);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setShowConfirmation(false);
  };

  return (
    <>
      <Button {...rest} onClick={handleConfirmClick}></Button>
      {showConfirmation && (
        <div className="confirmation-dialog">
          <p>{confirmationText}</p>
          <Button danger onClick={handleConfirm}>Yes</Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </div>
      )}
    </>
  );
};

export default ConfirmationButton;