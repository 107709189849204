import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import './Workout.css';
import './WorkoutSet.css'
import {
    addSetToUserWorkout,
    updateSetInUserWorkout,
    deleteSetInUserWorkout,
    addTemplateSetToUserWorkout,
    updateSetInTemplateUserWorkout,
    deleteSetInTemplateUserWorkout
} from '../../api/APIManager';
import DeleteButton from '../../components/DeleteButton';

function WorkoutSet({
    type,
    workout,
    onAddNewWorkoutSet,
    onEditSetInWorkout,
    onDeleteSetInWorkout }) {
    const [sets, setSets] = useState([]);

    useEffect(() => {
        setSets(workout.sets);
        console.log('WorkoutSets from useEffect');
        console.log(workout.sets);

    }, [workout]);

    function isProgramTemplateWorkoutSet() {
        if (type === 'template') {
            return true;
        }
        return false;
    }

    function isClientWorkoutSet() {
        if (type === 'client') {
            return true;
        }
        return false;
    }

    function isTrainersClientSet() {
        if (type === 'trainers-client') {
            return true;
        }
        return false;
    }

    const handleAddSet = async () => {
        const newSet = { setWeight: '', actualReps: '', repGoal: '' };
        setSets([...sets, newSet]);

        try {
            let userWorkoutResponse;
            if (isProgramTemplateWorkoutSet() && !isTrainersClientSet()) {
                userWorkoutResponse = await addTemplateSetToUserWorkout(workout._id, newSet);
            } else {
                userWorkoutResponse = await addSetToUserWorkout(workout._id, newSet);
            }

            if (!userWorkoutResponse || userWorkoutResponse.status > 299 || !userWorkoutResponse.data) {
                throw new Error('Invalid response from the server.');
            }

            let userWorkout = userWorkoutResponse.data
            const lastSet = userWorkout.sets[userWorkout.sets.length - 1];

            setSets(userWorkout.sets);
            onAddNewWorkoutSet(lastSet, workout)
        } catch (error) {
            console.log(error)
            // Rollback the optimistic update if there's an error
            setSets((prevSets) => prevSets.slice(0, -1));
        }
    };

    const handleSetChange = async (index, field, value) => {
        const updatedSets = sets.map((set, i) => {
            if (i === index) {
                return { ...set, [field]: value };
            }
            return set;
        });
        setSets(updatedSets, workout);

        try {
            let userWorkoutResponse;
            if (isProgramTemplateWorkoutSet() && !isTrainersClientSet()) {
                userWorkoutResponse = await updateSetInTemplateUserWorkout(workout._id, updatedSets[index]._id, updatedSets[index]);
            } else {
                userWorkoutResponse = await updateSetInUserWorkout(workout._id, updatedSets[index]._id, updatedSets[index]);
            }

            if (!userWorkoutResponse || userWorkoutResponse.status > 299 || !userWorkoutResponse.data) {
                throw new Error('Invalid response from the server.');
            }

            let userWorkout = userWorkoutResponse.data
            onEditSetInWorkout(userWorkout.sets[index], workout)
        } catch (error) {
            console.log(error)
        }
    };

    const handleDeleteSet = async (index) => {
        const setToDelete = sets[index]
        const updatedSets = sets.filter((set, i) => i !== index);
        setSets(updatedSets);

        onDeleteSetInWorkout(setToDelete, workout);

        if (isProgramTemplateWorkoutSet() && !isTrainersClientSet()) {
            await deleteSetInTemplateUserWorkout(workout._id, setToDelete._id);
        } else {
            await deleteSetInUserWorkout(workout._id, setToDelete._id);
        }
    };

    const showDeleteSetButtonIfAllowed = (index) => {
        if (!isClientWorkoutSet()) {
            return (
                <DeleteButton onClick={() => handleDeleteSet(index)}>Delete</DeleteButton>
            )
        }

        return null
    }

    const showAddSetButtonIfAllowed = () => {
        if (!isClientWorkoutSet()) {
            return (
                <Button warning onClick={handleAddSet}>Add Set</Button>
            )
        }

        return null
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // Process your form data here, e.g., send to an API
        console.log(sets);
    };

    return (
        <div className="workout-set-container">
            <div className="workout-set-header">
                <div className="workout-set-column"><strong>Set Weight</strong></div>
                <div className="workout-set-column"><strong>Actual Reps</strong></div>
                <div className="workout-set-column"><strong>Rep Goal</strong></div>
                {!isClientWorkoutSet() && (
                    <div className="workout-set-column"><strong> </strong></div>
                )}
            </div>
            {sets.map((set, index) => (
                <div key={set._id || `set-${index}`} className="workout-set-row">
                    <input
                        type="number"
                        inputMode="numeric"
                        value={set.setWeight ?? ''}
                        onChange={(e) => {
                            const numericValue = e.target.value.replace(/[^0-9]/g, '');
                            handleSetChange(index, 'setWeight', numericValue);
                        }}
                        className="workout-set-input"
                    />
                    <input
                        type="number"
                        inputMode="numeric"
                        value={set.actualReps ?? ''}
                        onChange={(e) => {
                            const numericValue = e.target.value.replace(/[^0-9]/g, '');
                            handleSetChange(index, 'actualReps', numericValue);
                        }}
                        className="workout-set-input"
                    />
                    <input
                        type="number"
                        inputMode="numeric"
                        value={set.repGoal ?? ''}
                        onChange={(e) => {
                            const numericValue = e.target.value.replace(/[^0-9]/g, '');
                            handleSetChange(index, 'repGoal', numericValue);
                        }}
                        className="workout-set-input"
                        readOnly={isClientWorkoutSet()}
                    />
                    {showDeleteSetButtonIfAllowed(index)}
                </div>
            ))}
            {showAddSetButtonIfAllowed()}
        </div>
    );
}

export default WorkoutSet;
