import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
    getActivity,
    updateActivity,
    getAllWorkoutsForActivity,
    createTemplateUserWorkout,
    createWorkoutAndAddToActivity,
    updateActivityForProgramTemplate,
    getWorkout,
    updateWorkout,
    deleteWorkout
} from '../shared/api/APIManager';
import Button from '../shared/components/Button';
import Modal from 'react-modal'; // Or your custom modal component
import "../shared/pages/ActivityDetailPage.css"
import Workout, { WorkoutViewType } from '../shared/pages/New/Workout';
import WorkoutsList from '../shared/pages/New/WorkoutsList';

function TrainerActivityDetailPage({ activity }) {
    const { id, sectionID, activityID } = useParams();
    const [currentActivity, setCurrentActivity] = useState(null);
    const [workouts, setWorkouts] = useState([]);
    const [newWorkout, setNewWorkout] = useState({}); // for creating a new workout
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const location = useLocation();
    const activityState = location.state?.selectedActivity;
    const [workoutToCreate, setWorkoutToCreate] = useState({
        title: '',
        muscleGroup: '',
        videoURL: '',
        description: '',
    });
    const [expandedWorkouts, setExpandedWorkouts] = useState({});

    useEffect(() => {
        // Fetch activity details
        // getActivity(activityID).then(setActivity);

        if (!activityState) {
            console.log("ActivityDetailPage - Need to call api since state was reloaded");
            // TODO: add api call to retrieve activity for when user reloads page
        } else {
            console.log(activityState)
            setCurrentActivity(activityState);
            console.log(activityState.workouts);
            setWorkouts(activityState.workouts);
            console.log('WOrkouts from useEffect');
            console.log(workouts);
        }

    }, []);

    const handleNewWorkoutChange = (e) => {
        const { name, value } = e.target;
        setNewWorkout({ ...newWorkout, [name]: value });
    };

    const handleCreateWorkout = async (e) => {
        e.preventDefault();
        await createWorkoutAndAddToActivity(activityID, newWorkout);
        // Optionally, refresh the workouts list
    };

    const handleSelectedWorkout = async (selectedWorkout) => {
        // Create new User Workout
        const newUserWorkout = {
            activityId: activityID,
            workoutId: selectedWorkout._id,
            isCompleted: false,
        }

        const userWorkoutResponse = await createTemplateUserWorkout(newUserWorkout);

        setWorkouts(prevWorkouts => {
            const updatedWorkouts = [...prevWorkouts, userWorkoutResponse.data];
            return updatedWorkouts;
        });

        const allWorkoutsToSend = [...workouts, userWorkoutResponse.data];
        const workoutIds = allWorkoutsToSend.map(workout => workout._id);
        await updateActivityForProgramTemplate(id, sectionID, activityID, {
            workouts: workoutIds
        });

        setIsModalOpen(false);
    }

    const updateActivity = async (workoutIds) => {
        const response = await updateActivity(currentActivity._id, {
            workouts: workoutIds
        });

        console.log(`Response: ${response}`);
    }

    // const handleAddWorkoutButtonTapped = () => {
    //     setIsModalOpen(true);
    // };

    const handleCloseModal = () => {
        // setActivity({
        //   title: '',
        // });
        setIsModalOpen(false);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        // setActivity({
        //   ...activity,
        //   [name]: value,
        // });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // try {
        // if (activity.title.length < 1) {
        //   // TODO: Add a message to the user
        //   // setMessage('Activity missing details');
        //   return
        // }

        //   const response = await createActivityAndAddToProgram(program._id, activity); // You may need to update createActivity
        //   setProgram({
        //     ...program,
        //     activities: [...program.activities, response.data],
        //   });
        // } catch (error) {
        //   console.error('Error creating activity:', error);
        // }
    };

    const toggleWorkoutExpanded = (workoutId) => {
        setExpandedWorkouts(prevState => ({
            ...prevState,
            [workoutId]: !prevState[workoutId]
        }));
    };


    const hanleUpdateWorkout = async (workout) => {
        console.log(`Updating workout: ${workout}`);
        const response = await getWorkout(workout._id);
        console.log(`Response: ${response}`);
        setWorkoutToCreate(response.data);
    }

    const handleDeleteWorkout = async (workout) => {
        console.log(`Deleting workout: ${workout}`);
        const reponse = await deleteWorkout(workout._id);
        console.log(`Response: ${reponse}`);

        setWorkouts(prevWorkouts => {
            const updatedWorkouts = prevWorkouts.filter(w => w._id !== workout._id);
            const workoutIds = updatedWorkouts.map(workout => workout._id);
            // Upload it to the server
            updateActivityForProgramTemplate(id, sectionID, activityID, {
                workouts: workoutIds
            });
            return updatedWorkouts;
        });
    }

    const renderedWorkouts = workouts.map((workout, index) => {
        return (
            <Workout key={workout._id} workout={workout} workoutType={'template'} isExpanded={!!expandedWorkouts[workout._id]} onExpandToggle={() => toggleWorkoutExpanded(workout._id)} deleteWorkout={() => handleDeleteWorkout(workout)} />
        )
    });

    return (
        <div>
            <div className="header">
                <div className="header-title">{activityState?.title}</div>
                {/* <div><Button primary onClick={() => handleAddWorkoutButtonTapped()}>Add Workout</Button></div> */}
            </div>
            <div className="subtitle">Workouts</div>
            <div className="boxes-wrapper">
                {renderedWorkouts.length > 0 ? renderedWorkouts : "No Workouts"}
            </div>
            <Modal
                className="workoutlist-modal"
                isOpen={isModalOpen} onRequestClose={() => handleCloseModal()}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.85)'
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        height: '80%',
                        backgroundColor: 'white'
                    }
                }}>
                <WorkoutsList onSelectedWorkout={handleSelectedWorkout} />
            </Modal>
        </div>
    );
}

export default TrainerActivityDetailPage;