import React, { useState } from "react";
import Button from "../../components/Button";
import DeleteButton from "../../components/DeleteButton";

import Workout from './Workout';

function ProgramItem({
    activity,
    sectionID,
    handleOnClick,
    isClient,
    isTemplate,
    handleDelete,
    onAddNewWorkoutButtonTap,
    handleAddWorkout,
    onCompleteWorkoutTapped,
    onDeleteWorkoutButtonTapped,
    onAddNewWorkoutSet,
    onEditSetInWorkout,
    onDeleteSetInWorkout
}) {
    const [expandedWorkouts, setExpandedWorkouts] = useState(activity.workouts);
    const [workouts, setWorkouts] = useState(activity.workouts);
    const showDeleteButton = () => {
        if (!isClient) {
            return (
                <div className="box-content" onClick={(e) => e.stopPropagation()}>
                    <DeleteButton onClick={() => handleDelete(activity)}>Delete {activity.title}</DeleteButton>
                </div>
            )
        } else {
            return null;
        }
    }

    // const handleTapAddNewWorkoutButton = () => {
    //     onAddNewWorkoutButtonTap(activity, sectionID)
    // }

    const showAddActivityButton = () => {
        if (!isClient) {
            return (
                <div className="box-content" onClick={(e) => e.stopPropagation()}>
                    <Button primary onClick={() => onAddNewWorkoutButtonTap(activity._id, sectionID)}>Add Workout</Button>
                </div>
            )
        } else {
            return null;
        }
    }

    // const handleAddWorkout = () => {
    //     console.log(`Adding workout to ${activity.title}`);
    // }

    // const handleAddWorkoutButtonTapped = () => {
    //     setIsModalOpen(true);
    //     console.log("Adding workout button tapped")
    //   };

    const toggleWorkoutExpanded = (workoutId) => {
        setExpandedWorkouts(prevState => ({
            ...prevState,
            [workoutId]: !prevState[workoutId]
        }));
    };

    const renderedWorkouts = activity.workouts.map((workout, index) => {
        let workoutType = 'client'
        if (!isClient) {
            if (isTemplate) {
                workoutType = 'template'
            } else {
                workoutType = 'trainers-client'
            }
        }

        return (
            <Workout
                key={workout._id}
                workout={workout}
                workoutType={workoutType}
                sectionId={sectionID}
                isExpanded={!!expandedWorkouts[workout._id]}
                onExpandToggle={() => toggleWorkoutExpanded(workout._id)}
                onCompleteWorkoutTapped={onCompleteWorkoutTapped}
                deleteWorkout={onDeleteWorkoutButtonTapped}
                onAddNewWorkoutSet={onAddNewWorkoutSet}
                onEditSetInWorkout={onEditSetInWorkout}
                onDeleteSetInWorkout={onDeleteSetInWorkout}
            />
        )
    });

    //   const handleDeleteWorkout = async (workout) => {
    //     console.log(`Deleting workout: ${workout}`);
    //     // const reponse = await deleteWorkout(workout._id);
    //     // console.log(`Response: ${reponse}`);

    //     // setWorkouts(prevWorkouts => {
    //     //   const updatedWorkouts = prevWorkouts.filter(w => w._id !== workout._id);
    //     //   const workoutIds = updatedWorkouts.map(workout => workout._id);
    //     //   // Upload it to the server
    //     //   updateActivityForProgram(id, activityID, {
    //     //     workouts: workoutIds
    //     //   });
    //     //   return updatedWorkouts;
    //     // });
    //   }

    return (
        <div className="box" onClick={(e) => {
            // e.stopPropagation();
            // handleOnClick(activity, sectionID);
        }}>
            <div className="box-content box-title-content">
                <div className="box-label">{activity.title}</div>
                {showDeleteButton()}
            </div>
            <div className="box-content">
                <div className="box-description">Workouts: {activity.workouts.length}</div>
            </div>
            <div>{/* <div className="box-content-workout"> */}
                {renderedWorkouts}
            </div>
            {showAddActivityButton()}
        </div>
    )
}

export default ProgramItem;