import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '../../shared/components/Button';
import ConfirmationButton from '../../shared/components/ConfirmationButton';
import {
    getClient,
    getClientProgram,
    createProgramFromProgramTemplate,
    updateProgram
} from '../../shared/api/APIManager';
import Modal from 'react-modal'; // Or your custom modal component
import ProgramSelectionPage from '../ProgramSelectionPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

function ClientDashboard() {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [client, setClient] = useState({
        firstName: '',
        lastName: '',
    });
    const [program, setProgram] = useState({
        title: '',
        description: '',
        sections: [],
    });
    const [nutritionPlan, setNutritionPlan] = useState({});
    const { id } = useParams();

    useEffect(() => {
        // Fetch the Client by ID
        getClient(id)
            .then((response) => {
                setClient(response.data);
            })
            .catch((error) => {
                console.error('An error occurred while fetching the data: ', error);
            });

        getClientProgram(id)
            .then((response) => {
                if (response.status === 404) {
                    setProgram(null);
                } else {
                    setProgram(response.data);
                }
            })
            .catch((error) => {
                setProgram(null);
                console.error('An error occurred while fetching the client program: ', error);
            });
    }, []);

    const handleClickOnProgram = () => {
        if (program === null) {
            console.error('Program is null');
            setIsModalOpen(true);
            // navigate(`/clients/${id}/dashboard/program-selection`, { state: { client } });
        } else {
            navigate(`/clients/${id}/dashboard/program`, {

            });
        }
    }

    const handleSelectedSavedProgram = async (selectedProgramTemplate) => {
        console.log(selectedProgramTemplate);
        setIsModalOpen(false);

        createProgramFromProgramTemplate(selectedProgramTemplate._id, client._id)
            .then((response) => {
                setProgram(response.data);
            })
            .catch((error) => {
                console.error('An error occurred while fetching the client program: ', error);
            });
    }

    const handleOnClickOnRemoveProgram = () => {
        setProgram(null);

        updateProgram(program._id, { client: null }).then((response) => {
            console.log(response)
        })
            .catch((error) => {
                console.error('An error occurred while removing the client from the program: ', error);
            });
    }

    const handleClickOnNutirionPlan = () => {
        navigate(`/clients/${id}/dashboard/nutrition`, {

        });
    }

    const programTitle = () => {
        let title = "Program"
        if (program) {
            title = `Program: ${program.title}`
        }

        return title;
    }

    const programDetailText = () => {
        let detailText = "No program assigned. Tap to assign one."
        if (program?.sections?.length > 0 && program?.sections.length < 2) {
            detailText = "1 total week"
        } else if (program?.sections?.length > 1) {
            detailText = `${program?.sections.length} total weeks`
        }

        return (
            <div className="box-content-title">{detailText}</div>
        )
    }

    const removeProgramButton = () => {
        if (program) {
            return (
                <div className="box-column">
                    <ConfirmationButton
                        warning
                        confirmationText="Are you sure you want to remove the program?"
                        onClick={handleOnClickOnRemoveProgram}
                    >
                        Remove Program <><FontAwesomeIcon icon={faTrash} className="section-button-icon" /></>
                    </ConfirmationButton>
                </div>
            )
        }
    }

    return (
        <div>
            <div className="header">
                <div className="header-title">Client: {client.firstName} {client.lastName}</div>
            </div>
            <div className="boxes-wrapper">
                <div className="box" onClick={() => handleClickOnProgram()}>
                    <div className="box-content">
                        <div className="box-column">
                            <div className="box-label">{programTitle()}</div>
                            {programDetailText()}
                        </div>
                        {removeProgramButton()}
                    </div>
                </div>
                <div className="box" onClick={() => handleClickOnNutirionPlan()}>
                    <div className="box-content">
                        <div className="box-label">Nutrition Plan</div>
                    </div>
                    <div className="box-content">
                        <div className="box-content-description">Coming Soon...</div>
                    </div>
                </div>
            </div>
            <Modal
                className="workoutlist-modal"
                isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.85)'
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        height: '80%',
                        backgroundColor: 'white'
                    }
                }}>
                <ProgramSelectionPage client={client} handleSelectedProgram={handleSelectedSavedProgram} />
            </Modal>
        </div>
    );
}

export default ClientDashboard;