import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useAuth, UserRole } from './shared/context/AuthProvider';
import Program from './client/Program';
import AuthenticationPage from './shared/pages/AuthenticationPage';
import AuthenticationPageOrAccountPage from './shared/pages/AuthenticationPageOrAccountPage';
import NotFoundPage from './shared/pages/NotFoundPage';
import TrainerProgramDetailPage from './trainer/TrainerProgramDetailPage';
import ActivityDetailPage from './shared/pages/ActivityDetailPage';
import { ProgramProvider } from './shared/context/ProgramContext';
import AccountPage from './shared/pages/AccountPage';
import ProgramList from './trainer/components/ProgramList';
import { NavigationProvider } from './shared/context/navigation';
import ClientList from './trainer/clients/ClientList';
import ClientDashboard from './trainer/clients/ClientDashboard';
import ClientProgram from './trainer/clients/ClientProgram';
import ClientNutrition from './trainer/ClientNutrition';
import MealPlan from './trainer/components/MealPlan';
import ResetPasswordPage from './shared/pages/ResetPasswordPage';
import WorkoutsList from './shared/pages/New/WorkoutsList';
import WorkoutDetail from './shared/components/WorkoutDetail';
import TrainerActivityDetailPage from './trainer/TrainerActivityDetailPage';
import ProgramSelectionPage from './trainer/ProgramSelectionPage';
import { useSwipeable } from 'react-swipeable';
import './App.css';

function App() {
  const { token } = useAuth();
  const [decodedToken, setDecodedToken] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    if (token !== null) {
      const decodedToken = jwtDecode(token);
      setDecodedToken(decodedToken);
    }

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [token]);

  const toggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  const handleMenuClick = () => {
    setIsMenuOpen(false);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setIsMenuOpen(false),
    onSwipedRight: () => setIsMenuOpen(true),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const loggedInClientContent = (
    <div className={`app-container ${isMenuOpen ? 'menu-open' : ''}`} {...swipeHandlers}>
      <Router>
        <NavigationProvider>
          <ProgramProvider>
            <div className="menu" ref={menuRef}>
              <img src={`${process.env.PUBLIC_URL}/ATF-Logo.jpg`} alt="ATF Logo" className="logo" />
              <ul>
                <li>
                  <NavLink to="/program" className={({ isActive }) => isActive ? "active-link" : ""} onClick={handleMenuClick}>Program</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/nutrition" className={({ isActive }) => isActive ? "active-link" : ""} onClick={handleMenuClick}>Nutrition</NavLink>
                </li> */}
                <li>
                  <NavLink to="/account" className={({ isActive }) => isActive ? "active-link" : ""} onClick={handleMenuClick}>Account</NavLink>
                </li>
              </ul>
            </div>
            <div className={`menu-overlay ${isMenuOpen ? 'open' : ''}`} onClick={() => setIsMenuOpen(false)} />
            <div className="content">
              <button className="hamburger" onClick={toggleMenu}>
                ☰
              </button>
              <Routes>
                <Route path="/program" element={<Program />} />
                <Route path="/programs/:id/section/:sectionID/activities/:activityID" element={<ActivityDetailPage />} />
                <Route path="/programs/:id/activities/:id/workouts" element={<ActivityDetailPage />} />
                <Route path="/activities/:id" element={<ActivityDetailPage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/login" element={<AuthenticationPageOrAccountPage />} />
                <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route */}
              </Routes>
            </div>
          </ProgramProvider>
        </NavigationProvider>
      </Router>
    </div>
  );

  const loggedInTrainerContent = (
    <div className={`app-container ${isMenuOpen ? 'menu-open' : ''}`} {...swipeHandlers}>
      <Router>
        <NavigationProvider>
          <ProgramProvider>
            <div className="menu" ref={menuRef}>
              <img src={`${process.env.PUBLIC_URL}/ATF-Logo.jpg`} alt="ATF Logo" className="logo" />
              <ul>
                <li>
                  <NavLink to="/clients" className={({ isActive }) => isActive ? "active-link" : ""} onClick={handleMenuClick}>Clients</NavLink>
                </li>
                <li>
                  <NavLink to="/program-templates" className={({ isActive }) => isActive ? "active-link" : ""} onClick={handleMenuClick}>Saved Programs</NavLink>
                </li>
                <li>
                  <NavLink to="/workouts" className={({ isActive }) => isActive ? "active-link" : ""} onClick={handleMenuClick}>All Workouts</NavLink>
                </li>
                <li>
                  <NavLink to="/nutrition" className={({ isActive }) => isActive ? "active-link" : ""} onClick={handleMenuClick}>Nutrition</NavLink>
                </li>
                <li>
                  <NavLink to="/account" className={({ isActive }) => isActive ? "active-link" : ""} onClick={handleMenuClick}>Account</NavLink>
                </li>
              </ul>
            </div>
            <div className={`menu-overlay ${isMenuOpen ? 'open' : ''}`} onClick={() => setIsMenuOpen(false)} />
            <div className="content">
              <button className="hamburger" onClick={toggleMenu}>
                ☰
              </button>
              <Routes>
                <Route path="/" element={<ClientList />} />
                <Route path="/clients" element={<ClientList />} />
                <Route path="/clients/:id/dashboard" element={<ClientDashboard />} />
                <Route path="/clients/:id/dashboard/program-selection" element={<ProgramSelectionPage />} />
                <Route path="/clients/:id/dashboard/program" element={<ClientProgram />} />
                <Route path="/clients/:id/dashboard/nutrition" element={<ClientNutrition />} />
                <Route path="/program-templates" element={<ProgramList />} />
                <Route path="/program-templates/:id" element={<TrainerProgramDetailPage />} />
                <Route path="/programs/:id/activities/:activityID" element={<ActivityDetailPage />} />
                <Route path="/programs/:id/section/:sectionID/activities/:activityID" element={<ActivityDetailPage />} />
                <Route path="/program-templates/:id/section/:sectionID/activities/:activityID" element={<TrainerActivityDetailPage />} />
                <Route path="/program-templates/:id/section/:sectionID/activities/:activityID/workouts" element={<TrainerActivityDetailPage />} />
                <Route path="/program-templates/:id/activities/:activityID/workouts/:id" element={<WorkoutDetail />} />
                <Route path="/activities/:id" element={<TrainerActivityDetailPage />} />
                <Route path="/nutrition" element={<MealPlan />} />
                <Route path="/workouts" element={<WorkoutsList />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/login" element={<AuthenticationPageOrAccountPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </div>
          </ProgramProvider>
        </NavigationProvider>
      </Router>
    </div>
  );

  if (window.location.pathname === '/reset-password') {
    return (
      <div className="container mx-5 grid grid-cols-6 gap-4 mt-4">
        <ResetPasswordPage />
      </div>
    );
  } else {
    if (decodedToken) {
      console.log(decodedToken);

      return (
        <div className="container mx-5 grid grid-cols-6 gap-4 mt-4">
          {decodedToken.role === UserRole.ADMIN ? loggedInTrainerContent :
            decodedToken.role === UserRole.TRAINER ? loggedInTrainerContent :
              decodedToken.role === UserRole.CLIENT ? loggedInClientContent :
                <AuthenticationPage />}
        </div>
      );
    } else {
      return (
        <div className="container mx-5 grid grid-cols-6 gap-4 mt-4">
          <AuthenticationPage />
        </div>
      );
    }
  }
}

export default App;
