import React, { useState } from 'react';
import './Workout.css';
import WorkoutSet from './WorkoutSet';
import Button from '../../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSave, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faSquareCheck, faSquare } from '@fortawesome/free-regular-svg-icons';
import DeleteButton from '../../components/DeleteButton';
export const WorkoutViewType = {
    client: 'client',
    template: 'template',
    workoutList: 'workout-list'
};

function Workout({
    viewType,
    workout,
    workoutType,
    sectionId,
    isExpanded,
    onExpandToggle,
    onUserClicked,
    updateWorkout,
    onCompleteWorkoutTapped,
    deleteWorkout,
    onAddNewWorkoutSet,
    onEditSetInWorkout,
    onDeleteSetInWorkout }) {
    const isTemplateWorkout = viewType === WorkoutViewType.template;
    const isFromWorkoutList = viewType === WorkoutViewType.workoutList;
    const isTrainersClientWorkout = workoutType === 'trainers-client';
    const isClientWorkout = workoutType === 'client';
    const shouldShowCheckBoxView = isTrainersClientWorkout || isClientWorkout;
    const shouldShowDeleteButton = isTrainersClientWorkout || !isClientWorkout
    const [isCompleted, setIsCompleted] = useState(workout.isCompleted ?? false);

    const handleCheckBoxToggle = () => {
        const updatedCompletedStatus = !isCompleted;
        setIsCompleted(updatedCompletedStatus);
        onCompleteWorkoutTapped(workout, updatedCompletedStatus);
    };

    const workoutVideoView = (
        <div className="workout-box-content">
            <div className="video-wrapper">
                <iframe
                    width="560"
                    height="315"
                    title={workout.name}
                    src={`${workout.videoUrl}?dnt=1`} 
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen>
                </iframe>
            </div>
        </div>
    );

    if (isFromWorkoutList) {
        return (
            <div className="box" onClick={() => onUserClicked(workout)}>
                <div className="workout-box-content">
                    <div className="workout-title-container">
                        <div className="workouts-box-label-collapsed">{workout.name}</div>
                        <div className="box-description">Muscle Group: {workout.muscleGroup}</div>
                    </div>
                </div>
                {workoutVideoView}
            </div>
        );
    } else if (isTemplateWorkout) {
        return (
            <div className="box">
                <div className="workout-box-content">
                    <div className="workout-title-container">
                        <div className="box-label">{workout.workout.name}</div>
                        <div className="box-description">Muscle Group: {workout.workout.muscleGroup}</div>
                    </div>
                </div>
                <div className="workout-box-content">
                    <input className="box-description" type="text" placeholder="Sets" />
                    <input className="box-description" type="text" placeholder="Reps per set" />
                    <input className="box-description" type="text" placeholder="Weight" />
                    <div className="button-container">
                        <Button warning onClick={() => updateWorkout(workout.workout)}>
                            <FontAwesomeIcon icon={faSave} />Update
                        </Button>
                        <DeleteButton onClick={() => deleteWorkout(workout.workout)}>
                            <FontAwesomeIcon icon={faTrash} />Delete
                        </DeleteButton>
                    </div>
                </div>
                {workoutVideoView}
            </div>
        );
    } else {
        const workoutSetView = (
            <div className="workout-box-content">
                <WorkoutSet
                    type={workoutType}
                    key={workout.id}
                    workout={workout}
                    onAddNewWorkoutSet={onAddNewWorkoutSet}
                    onEditSetInWorkout={onEditSetInWorkout}
                    onDeleteSetInWorkout={onDeleteSetInWorkout}
                />
            </div>
        );

        const workoutVideoViewForClient = (
            <div className="workout-box-content">
                <div className="video-wrapper">
                    <iframe
                        width="560"
                        height="315"
                        title={workout.workout.name}
                        src={`${workout.workout.videoUrl}?dnt=1`}
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
        );

        const checkBoxView = (
            <div onClick={handleCheckBoxToggle} style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon key={workout._id} icon={isCompleted ? faSquareCheck : faSquare} />
            </div>
        );

        return (
            <div className="box box-workout">
                <div className="workout-box-content">
                    <div className="workout-title-container" onClick={onExpandToggle}>
                        <div className="workout-title-container">
                            {shouldShowCheckBoxView && <>{checkBoxView} </>}

                            <span className="workouts-box-label-collapsed" style={{ marginLeft: shouldShowCheckBoxView ? '10px' : '0' }}>
                                {workout.workout.name}
                            </span>
                        </div>
                        {!isExpanded && (
                            <div className="total-sets">Total Sets: {workout.sets.length}</div>
                        )}
                        <div className="expand-icon">
                            {isExpanded && (
                                <FontAwesomeIcon icon={faChevronDown} />
                            )}
                            {!isExpanded && (
                                <FontAwesomeIcon icon={faChevronUp} />
                            )}
                        </div>
                    </div>
                    {isExpanded && (
                        <>
                            {workoutSetView}
                            {workoutVideoViewForClient}
                            {shouldShowDeleteButton && (
                                <DeleteButton onClick={() => deleteWorkout(sectionId, workout.activityId, workout._id)}>
                                    <FontAwesomeIcon icon={faTrash} />Delete Workout
                                </DeleteButton>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default Workout;